window.ilr = require('./build/templates').ilr;

var jQuery = require('jquery');

// History.js
require('./history');

// Clipboard
window.ClipboardJS = require('clipboard');

// Mousetrap
window.Mousetrap = require('mousetrap');

// Linkify
window.linkifyHtml = require('linkifyjs/html');

// Moment.js
window.moment = require('moment-timezone');

// Cookies
window.Cookies = require('js-cookie');

// Foundation
require('foundation-sites/js/foundation/foundation');
require('foundation-sites/js/foundation/foundation.tab');

// Templates
window.Handlebars = require('handlebars');

// Site JavaScript
require('./build/scripts');